import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import {YnaRed} from "../../components/common/logo/YnaRed";

export default () => {
    return <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: '100vh',
        minWidth: '100vw',
        backgroundColor: 'rgb(238, 245, 251)' }}>
        <Card sx={{
            width: "90vw",
            height: "90vh",
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'stretch',
            flexWrap: 'wrap' }}>
            <CardHeader avatar={<a href="/"><YnaRed/></a>}/>
            <CardContent>
                <Typography variant="h6" component="span">У вас нет доступа для просмотра этой страницы</Typography>
            </CardContent>
        </Card>
    </div>
}