import AppApi from "./AppApi";
import AppStore from "../stores/Store";

export default class SettingsApi {
    constructor(private api: AppApi, private store: AppStore) {
        this.api = api;
        this.store = store;
    }

    async linkExternalService(userId: string, accessToken: string, accessTokenSecret: string, service: string) {
        if (this.store.settingsStore.isLinkStarted()) {
            return;
        }

        const token = await this.store.authStore.getAuthToken();
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        this.store.settingsStore.setLinkStarted(true);
        await this.api.client.post(
            `/auth/api/v1/external/services/link`,
            {
                userId: userId,
                accessToken: accessToken,
                accessTokenSecret: accessTokenSecret,
                providerName: service
            },
            config
        );
        this.store.settingsStore.setLinkStarted(false);
        
        this.store.authStore.addService(service);
    }
    
    async removeExternalService(service: string) {
        if (this.store.settingsStore.isLinkStarted()) {
            return;
        }

        const token = await this.store.authStore.getAuthToken();
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        
        this.store.settingsStore.setLinkStarted(true);
        await this.api.client.post(
            `/auth/api/v1/external/services/unlink`,
            {
                providerName: service
            },
            config
        );
        this.store.settingsStore.setLinkStarted(false);
        this.store.authStore.removeService(service);
    }

    close() {
        this.store.settingsStore.close();
    }
    
    open() {
        this.store.settingsStore.open();
    }
}