import {DefaultComponentProps} from "@mui/material/OverridableComponent";
import {Grid} from "@mui/material";

export const YnaRed = (props: DefaultComponentProps<any>) => {
  return (
    <Grid className={props.className}>
      <svg width="156" height="40" viewBox="0 0 156 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="41.7163" height="40" rx="20" fill="#FF3147"/>
        <path
          d="M23.3055 28.4292L16.7441 22.1972L19.0164 19.4149L22.9736 23.1726L32.4454 11.571L35.2078 13.8503L23.3055 28.4292Z"
          fill="white"/>
        <path
          d="M9.27936 28.4292L6.50879 26.1499L18.4243 11.571L24.9725 17.7773L22.6977 20.5648L18.7566 16.8277L9.27936 28.4292Z"
          fill="white"/>
        <path
          d="M110.668 31.9585L101.282 23.1017L104.532 19.1475L110.193 24.4879L123.742 8L127.694 11.2392L110.668 31.9585Z"
          fill="#FF3147"/>
        <path
          d="M90.7562 31.9731L86.7974 28.7339L103.823 8.01465L113.18 16.8349L109.93 20.7964L104.298 15.4853L90.7562 31.9731Z"
          fill="#FF3147"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M147.294 26.9027L151.44 31.9585L155.267 28.5734L138.38 8.01465L121.485 28.5734L125.313 31.9585L129.461 26.9027H147.294ZM143.106 21.7959L138.38 16.0324L133.651 21.7959H143.106Z"
          fill="#FF3147"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M80.2981 26.7451V31.9585H74.5132V26.7692L61.855 11.5238L66.106 8.01465L77.3984 21.6062L88.6615 8.01465L92.9125 11.5238L80.2981 26.7451Z"
          fill="#FF3147"/>
      </svg>
    </Grid>
    );
};
