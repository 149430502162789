import {Route, Routes} from "react-router-dom";
import RequireAuth from "../utils/RequireAuth";
import LoginPage from "../pages/AuthPage";
import DashboardPage from "../pages/DashboardPage";
import {
    AddExternalEmailForm,
    ChangePasswordForm,
    LoginForm,
    RegisterForm,
    VerifyCodeForm,
} from "../pages/AuthPage/components";
import AddMeToCoach from "../pages/AddMeToCoach";
import TrainAnalysis from "../pages/TrainAnalysis";
import {MainPage} from "../pages/MainPage/MainPage";
import {VerifyCodeTypes} from "../pages/AuthPage/types";
import {ResetPasswordForm, GetTokenForm} from "../pages/AuthPage/components";
import AccessDenied from "../pages/AccessDenied";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/login" element={<LoginPage />}>
        <Route index element={<LoginForm />} />
        <Route path="register" element={<RegisterForm />} />
        <Route path="reset-password" element={<GetTokenForm />} />
        <Route path="new-password" element={<ResetPasswordForm />} />
        <Route path="change-password" element={<ChangePasswordForm />} />
        <Route path="link-email" element={<AddExternalEmailForm />} />
        <Route path="verify-code" element={<VerifyCodeForm type={VerifyCodeTypes.registration} />} />
        <Route path="restore-pwd-verify-code" element={<VerifyCodeForm type={VerifyCodeTypes.restorePassword} />} />
      </Route>
      <Route
        path="/analysis/:id"
        element={
          <RequireAuth>
            <TrainAnalysis />
          </RequireAuth>
        }
      />
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard/:athleteId"
        element={
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        }
      />
      <Route
        path="addmetocoach/:linkId"
        element={
          <RequireAuth>
            <AddMeToCoach />
          </RequireAuth>
        }
      />
      <Route
        path="accessDenied"
        element={
          <AccessDenied />
        } />
    </Routes>
  );
};

export default App;
