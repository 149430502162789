import List from "@mui/material/List";
import ServiceListItem from "./components/ServiceListItem";
import styled from "@emotion/styled";

export type ConnectedServiceListProps = {
    visible: boolean;
    onConnect?: (service: string) => void;
    onDisconnect?: (service: string) => void;
    services?: string[]
}

export default (props: ConnectedServiceListProps) => {
    const isConnected = (service: string) : boolean => props.services ? props.services?.includes(service) : false;
    
    return props.visible ?
        <>
            <STitle>Подключите аккаунты спортивных приложений для автоматической синхронизации Ваших тренировок с YNA</STitle>
            <List>
                {/*<ServiceListItem  onConnect={props.onConnect} connected={isConnected("Strava")} service={"Strava"} />*/}
                <ServiceListItem
                    onConnect={props.onConnect}
                    onDisconnect={props.onDisconnect}
                    connected={isConnected("Garmin")}
                    service={"Garmin"} />
            </List>
        </>
        : <></>
};

const STitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #1A222D;
    padding-bottom: 44px;
`;