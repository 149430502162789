import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

function SecondsToTime(totalSeconds: number) {
  if (!totalSeconds || Number.isNaN(totalSeconds)) {
    return dayjs()
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0).format("HH:mm:ss");
  }

  const totalHours = Math.floor(totalSeconds / (60 * 60));
  totalSeconds = totalSeconds - totalHours * 60 * 60;

  const totalMinutes = Math.floor(totalSeconds / 60);
  totalSeconds = totalSeconds - totalMinutes * 60;

  const dj = dayjs()
    .set("hour", totalHours)
    .set("minute", totalMinutes)
    .set("second", totalSeconds);

  return dj.format("HH:mm:ss");
}

function SecondsToMin(totalSeconds: number) {
  if (!totalSeconds || Number.isNaN(totalSeconds)) {
    return dayjs().set("minute", 0).set("second", 0).format("mm:ss");
  }
  
  const totalMinutes = Math.floor(totalSeconds / 60);
  totalSeconds = totalSeconds - totalMinutes * 60;
  const dj = dayjs().set("minute", totalMinutes).set("second", totalSeconds);
  return dj.format("mm:ss");
}

export { SecondsToTime, SecondsToMin };
