import { action, makeAutoObservable, observable } from "mobx";
import Store from "./Store";
import { WorkoutType } from "../components/scheduler/types";
import dayjs, { Dayjs } from "dayjs";

type AddStateType = {
  dateStart: Date | null;
};

type WorkoutMenuState = {
  displayDelete: boolean;
  displayUnmerge: boolean;
  displayMerge: boolean;
};

export type WorkoutData = {
  id: string | undefined | null;
  athleteId: string;
  name: string;
  startDate: Date;
  type: string;
  description: string;
  target: boolean;
  plan: string;
  fact: string;
  indicators?: string;
  createdEmpty: boolean;
};

export type WorkoutId = {
  id: string;
  last: boolean;
  first: boolean;
};

export default class WorkoutStore {
  setSchedulerCurrentDate(date: Dayjs): void {
    this.currentDate = date;
  }
  
  currentDate: Dayjs = dayjs();

  workoutData: WorkoutData = {
    id: undefined,
    athleteId: "",
    name: "",
    startDate: new Date(),
    type: WorkoutType[WorkoutType.running],
    description: "",
    target: false,
    plan: "",
    fact: "",
    createdEmpty: false,
  };

  workoutContentData = {};
  factWorkoutContentData = {};
  displayState = {
    workoutTabState: 0,
  };

  workoutList: WorkoutId[] = [];

  reset() {
    this.workoutData = {
      id: undefined,
      athleteId: "",
      name: "",
      startDate: this.addState.dateStart || new Date(),
      type: WorkoutType[WorkoutType.running],
      description: "",
      target: false,
      plan: "",
      fact: "",
      createdEmpty: false,
    };

    this.workoutContentData = {};
    this.factWorkoutContentData = {};
    this.displayState = { workoutTabState: 0 };
  }

  setWorkoutData(key: any, value: any) {
    //@ts-ignore
    this.workoutData[key] = value;
    console.log("workoutData", value);
  }

  setWorkoutContentData(key: any, value: any) {
    //@ts-ignore
    this.workoutContentData[key] = value;
  }

  setFactWorkoutContentData(key: any, value: any) {
    //@ts-ignore
    this.factWorkoutContentData[key] = value;
  }

  removeFromItems(itemId: string) {
    this.items = this.items.filter((item) => item.id !== itemId);
  }

  items: WorkoutData[] = observable.array();

  dateStart: Date | undefined;

  dateEnd: Date | undefined;

  goToToday: number = 0;

  mergePlanId: string | undefined;

  updateGoToToday() {
    this.currentDate = dayjs();
  }

  addState: AddStateType = {
    dateStart: null,
  };

  workoutMenuState: WorkoutMenuState = {
    displayDelete: false,
    displayUnmerge: false,
    displayMerge: false,
  };

  setDeleteDisplayModal(show: boolean) {
    this.workoutMenuState.displayDelete = show;
  }

  setUnmergeDisplayModal(show: boolean) {
    this.workoutMenuState.displayUnmerge = show;
  }

  setMergeDisplayModal(show: boolean) {
    this.workoutMenuState.displayMerge = show;
  }

  setDateStart(value: Date) {
    this.addState.dateStart = value;
  }

  setCalendarDateStart(value: Date) {
    this.dateStart = value;
  }

  setWorkoutItems(items: WorkoutData[]) {
    this.items = items;
  }

  ///теперь мы не перезаписываем все данные, а пере
  replaceWorkoutItems(items: WorkoutData[], dateStart: Date, dateEnd: Date) {
    this.items = this.items.filter((item) => {
      return (
        (new Date(item.startDate) <= dateStart ||
          new Date(item.startDate) >= dateEnd) &&
        !items.some((x) => x.id === item.id)
      );
    });
    this.items.push(...items);
  }

  addWorkoutItem(item: WorkoutData) {
    this.items.push(item);
  }

  setWorkoutTabState(tabIndex: number) {
    this.displayState.workoutTabState = tabIndex;
  }

  editWorkout(item: WorkoutData) {
    // this.items = [];
    this.workoutData = item;
    console.log("edit", item);
    const workoutContentData = JSON.parse(item.plan) || {};
    this.workoutContentData = {
      ...workoutContentData,
      distance: workoutContentData.distance || undefined,
      time: workoutContentData.time ? workoutContentData.time : 0,
      pace: workoutContentData.pace ? workoutContentData.pace : 0,
      startDate: workoutContentData.startDate
        ? dayjs(workoutContentData.startDate)
        : undefined,
      pulse: workoutContentData.pulse || undefined,
    };
    const factWorkoutContentData = JSON.parse(item.fact) || {};
    this.factWorkoutContentData = {
      ...factWorkoutContentData,
      distance: factWorkoutContentData.distance || undefined,
      time: factWorkoutContentData.time ? factWorkoutContentData.time : 0,
      pace: factWorkoutContentData.pace ? factWorkoutContentData.pace : 0,
      startDate: factWorkoutContentData.startDate
        ? dayjs(factWorkoutContentData.startDate)
        : undefined,
      pulse: factWorkoutContentData.pulse || undefined,
    };
  }

  setWorkoutList(workouts: WorkoutId[]) {
    this.workoutList = workouts.map((item) => item);
  }

  setMergeWorkoutId(workoutId: string) {
    this.mergePlanId = workoutId;
  }

  constructor(private store: Store) {
    makeAutoObservable(this, {
      setDateStart: action.bound,
      setCalendarDateStart: action.bound,
      setWorkoutData: action.bound,
      setWorkoutItems: action.bound,
      reset: action.bound,
      setWorkoutTabState: action.bound,
      editWorkout: action.bound,
      workoutData: observable,
      goToToday: observable,
      currentDate: observable,
      setSchedulerCurrentDate: action.bound,
      workoutList: observable,
      setWorkoutList: action.bound,
      setDeleteDisplayModal: action.bound,
      setUnmergeDisplayModal: action.bound,
      setMergeWorkoutId: action.bound,
    });
  }
}
