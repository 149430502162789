import {Backdrop, CircularProgress} from "@mui/material";
import {useAppContext} from "../../AppContext";
import {useObserver} from "mobx-react";

export default () => {
    const {
        store: { detailStore },
    } = useAppContext();

    return useObserver(() =>  <Backdrop open={detailStore.loading} sx={{zIndex: 1001}}>
        <CircularProgress
            sx={(theme) => ({color: '#fff', zIndex: 1001})}
            color="inherit"/>
    </Backdrop>);
}