import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "../../styles";
import { observer } from "mobx-react";
import { useAppContext } from "../../AppContext";
import { useCallback } from "react";
import { SecondsToMin, SecondsToTime } from "../../utils/time";
import { countSummaryData } from "../../stores/WorkoutDetailStore";
import styled from "@emotion/styled";
import { useFlag } from "@unleash/proxy-client-react";
import SegmentsToggle from "./components/SegmentsToggle";
import { AnalysisDisplayType } from "./types";
import { DisplayModeTime } from "./DisplayModeTime";

const useStyles = makeStyles()({
  container: {
    backgroundColor: "#FFF",
    maxHeight: "430px",
    overflow: "scroll",
    scrollbarWidth: "thin",
  },
  tableCell: {
    align: "right",
  },
  tbody: { maxHeight: "430px", overflow: "scroll" },
});
export default observer(() => {
  const segmentEnabled = useFlag("analyze.segments");
  const {
    store: { detailStore },
  } = useAppContext();
  const { classes } = useStyles();

  function hasAny(arr: any, fieldName: string) {
    if (arr == null) return false;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][fieldName] != null) return true;
    }
    return false;
  }

  function sum(arr: any, fieldName: string) {
    let summaryData = 0;
    if (arr == null) return summaryData;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][fieldName]) summaryData = summaryData + arr[i][fieldName];
    }
    return summaryData;
  }

  const summary = countSummaryData(detailStore.streamData);

  const onRowClick = useCallback(
    (idx: number) => {
      if (detailStore.workoutDetails == null) return;
      detailStore.setCurrentIdx(idx);
    },
    [detailStore]
  );

  const onSegmentModeTimeClick = useCallback(() => {
    detailStore.changeModeTime();
  }, [detailStore]);

  const hasCadence = hasAny(detailStore.workoutDetails, "cadence");
  const hasPace = hasAny(detailStore.workoutDetails, "pace");
  const hasCadenceMax = hasAny(detailStore.workoutDetails, "cadenceMax");
  const hasHeartRate = hasAny(detailStore.workoutDetails, "heartRate");
  const hasMaxHeartRate = hasAny(detailStore.workoutDetails, "heartRateMax");
  const hasUp = hasAny(detailStore.workoutDetails, "up");
  const hasDown = hasAny(detailStore.workoutDetails, "down");

  const summaryUp = sum(detailStore.workoutDetails, "up");
  const summaryDown = sum(detailStore.workoutDetails, "down");

  const displaySegmentMode =
    detailStore.analysisDisplayType === AnalysisDisplayType.Segment &&
    segmentEnabled;

  console.log(detailStore.workoutDetails);

  return (
    <div>
      {segmentEnabled && <SegmentsToggle />}
      <div className={classes.container}>
        <Table
          stickyHeader={true}
          sx={{
            "& .MuiTableRow-root:hover": {
              backgroundColor: "#EEFFF6",
            },
          }}
        >
          <TableHead>
            <SRow>
              {displaySegmentMode && <SHeaderCell>Сегмент</SHeaderCell>}
              {detailStore.analysisDisplayType !==
                AnalysisDisplayType.Segment && (
                <SHeaderCell>
                  Расстояние
                  <SSecondText>км</SSecondText>
                </SHeaderCell>
              )}
              {detailStore.analysisDisplayType ===
                AnalysisDisplayType.Segment && (
                <SHeaderCell>
                  Расстояние
                  <SSecondText>км</SSecondText>
                </SHeaderCell>
              )}
              {(!hasPace ||
                detailStore.analysisDisplayType ===
                  AnalysisDisplayType.Distance) && (
                <SHeaderCell className={classes.tableCell}>Время</SHeaderCell>
              )}
              {hasPace &&
                detailStore.analysisDisplayType ===
                  AnalysisDisplayType.Segment && (
                  <SHeaderCell className={classes.tableCell}>
                    Время
                    <SSecondTextWithLink onClick={onSegmentModeTimeClick}>
                      {detailStore.modeTime === DisplayModeTime.Segment
                        ? "сегмента"
                        : "общее"}
                    </SSecondTextWithLink>
                  </SHeaderCell>
                )}
              {hasPace && (
                <SHeaderCell className={classes.tableCell}>
                  Ср. темп
                  <SSecondText>мин/км</SSecondText>
                </SHeaderCell>
              )}
              {hasCadence && (
                <SHeaderCell className={classes.tableCell}>
                  Ср. каденс&nbsp;
                  <SSecondText>шаги/мин</SSecondText>
                </SHeaderCell>
              )}
              {hasCadenceMax && (
                <SHeaderCell className={classes.tableCell}>
                  Макс каденс
                  <SSecondText>шаги/мин</SSecondText>
                </SHeaderCell>
              )}
              {hasHeartRate && (
                <SHeaderCell className={classes.tableCell}>
                  Ср. пульс
                  <SSecondText>уд/мин</SSecondText>
                </SHeaderCell>
              )}
              {hasMaxHeartRate && (
                <SHeaderCell className={classes.tableCell}>
                  Макс. пульс
                  <SSecondText>уд/мин</SSecondText>
                </SHeaderCell>
              )}
              {hasUp && (
                <SHeaderCell className={classes.tableCell}>
                  Подъем
                  <SSecondText>м</SSecondText>
                </SHeaderCell>
              )}
              {hasDown && (
                <SHeaderCell className={classes.tableCell}>
                  Спуск
                  <SSecondText>м</SSecondText>
                </SHeaderCell>
              )}
            </SRow>
          </TableHead>
          <TableBody className={classes.tbody}>
            {detailStore.workoutDetails?.map((item, idx) => {
              return (
                <SRow
                  key={item.idx}
                  onClick={() => {
                    item.distance !== -1 && onRowClick(item.idx - 1);
                  }}
                >
                  {displaySegmentMode && (
                    <SCell component="th" scope="row">
                      {item.idx}
                    </SCell>
                  )}
                  {detailStore.analysisDisplayType !==
                    AnalysisDisplayType.Segment && (
                    <SCell component="th" scope="row">
                      {!Number.isNaN(item.distance) ? item.distance === -1 ? "" : item.distance : 0}
                    </SCell>
                  )}
                  {detailStore.analysisDisplayType ===
                    AnalysisDisplayType.Segment && (
                    <SCell component="th" scope="row">
                      {item.segmentDistance === -1
                        ? ""
                        : item.segmentDistance.toFixed(2)}
                    </SCell>
                  )}
                  {(!hasPace ||
                    detailStore.analysisDisplayType ===
                      AnalysisDisplayType.Distance ||
                    detailStore.modeTime === DisplayModeTime.Summary) && (
                    <SCell className={classes.tableCell}>
                      {SecondsToTime(item.time)}
                    </SCell>
                  )}
                  {hasPace &&
                    detailStore.analysisDisplayType ===
                      AnalysisDisplayType.Segment &&
                    detailStore.modeTime === DisplayModeTime.Segment && (
                      <SCell className={classes.tableCell}>
                        {SecondsToTime(item.segmentTime)}
                      </SCell>
                    )}

                  {hasPace && (
                    <SCell className={classes.tableCell}>
                      {SecondsToMin(item.pace)}
                    </SCell>
                  )}
                  {hasCadence && (
                    <SCell className={classes.tableCell}>{!Number.isNaN(item.cadence) ? item.cadence : 0}</SCell>
                  )}
                  {hasCadenceMax && (
                    <SCell className={classes.tableCell}>
                      {!Number.isNaN(item.cadenceMax) ? item.cadenceMax : 0}
                    </SCell>
                  )}
                  {hasHeartRate && <SCell>{!Number.isNaN(item.heartRate) ? item.heartRate : 0}</SCell>}
                  {hasMaxHeartRate && <SCell>{!Number.isNaN(item.heartRateMax) ? item.heartRateMax : 0}</SCell>}
                  {hasUp && <SCell>{item.up}</SCell>}
                  {hasDown && <SCell>{item.down}</SCell>}
                </SRow>
              );
            })}
          </TableBody>
          <TableFooter
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#FFF",
              borderTop: "solid 1px",
            }}
          >
            <SRow>
              {displaySegmentMode && <SCell></SCell>}
              <SCell component="th" scope="row">
                {/* <SummarySpan>{summary.distanceExact}</SummarySpan> */}
              </SCell>
              <SCell className={classes.tableCell}>
                <SummarySpan>{SecondsToTime(summary.time)}</SummarySpan>
              </SCell>
              {hasPace && (
                <SCell className={classes.tableCell}>
                  <SummarySpan>
                    {SecondsToMin(summary.pace / summary.distanceExact)}
                  </SummarySpan>
                </SCell>
              )}
              {hasCadence && (
                <SCell className={classes.tableCell}>
                  <SummarySpan>{!Number.isNaN(summary.cadence) ? summary.cadence : 0}</SummarySpan>
                </SCell>
              )}
              {hasCadenceMax && (
                <SCell className={classes.tableCell}>
                  <SummarySpan>{!Number.isNaN(summary.cadenceMax) ? summary.cadenceMax : 0}</SummarySpan>
                </SCell>
              )}
              {hasHeartRate && (
                <SCell>
                  <SummarySpan>{!Number.isNaN(summary.heartRate) ? summary.heartRate : 0}</SummarySpan>
                </SCell>
              )}
              {hasMaxHeartRate && (
                <SCell>
                  <SummarySpan>{!Number.isNaN(summary.heartRateMax) ? summary.heartRateMax : 0}</SummarySpan>
                </SCell>
              )}
              {hasUp && (
                <SCell>
                  <SummarySpan>{summaryUp}</SummarySpan>
                </SCell>
              )}
              {hasDown && (
                <SCell>
                  <SummarySpan>{summaryDown}</SummarySpan>
                </SCell>
              )}
            </SRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
});

const SummarySpan = styled.span`
  color: var(--Black, #1a222d);
  /* Txt 12 Semibold */
  font-family: Golos Text;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14.4px;
`;

const SHeaderCell = styled(TableCell)`
  font-family: Golos Text;
  font-weight: 400;
  font-style: normal;
  font-style: normal;
  line-height: normal;
  vertical-align: top;
  padding: 8px;
`;

const SSecondText = styled.div`
  color: #7b8a9e;
  font-size: 10px;
  line-height: 12px;
`;

const SSecondTextWithLink = styled.div`
  color: #7b8a9e;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const SRow = styled(TableRow)`
  height: 30px;
`;

const SCell = styled(TableCell)`
  padding: 8px;
  color: var(--Black, #1a222d);
  /* Txt 12 Semibold */
  font-family: Golos Text;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.4px;
`;

