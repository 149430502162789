import {OAuthResultEvent} from "../../login/types";
import SettingsApi from "../../../apis/SettingsApi";

export default function useExternalService(settingsApi: SettingsApi) {
    const getProvider = (service: string) => {
        switch (service) {
            case "Strava":
                return 'strava';
            default:
                return 'garmin';
        }
    }

    const createOAuthMessageHandler = async (event: OAuthResultEvent) => {
        if (event.data?.type === "link_service" && event.data?.origin === "yna") {
            if (event.data.payload.Error) {
                return new Promise((_, reject) =>
                    reject({ needEmail: false, result: false, error: event.data.payload.Error }));
            }

            if (event.data?.payload) {
                await settingsApi.linkExternalService(
                    event.data.payload.UserId,
                    event.data.payload.AccessToken,
                    event.data.payload.AccessTokenSecret,
                    event.data.payload.ProviderName
                );
            }

            return new Promise(resolve => resolve({
                needEmail: event.data.payload.NeedEmail,
                identifier: event.data.payload.Identifier,
                provider: event.data.payload.Provider,
                redirect: event.data.payload.Redirect,
                result: true,
                error: undefined
            }));
        }

        return new Promise((_, reject) => reject({ needEmail: false, result: false }))
    }
    
    const openOAuthWindow = async (service: string) : Promise<void> => {
        const provider = getProvider(service);
            window.addEventListener(
                'message',
                async (e) => {
                    try {
                        await createOAuthMessageHandler(e);
                    } catch (e) {
                        console.error(e);
                    }
                },
                { once: true },
            );
            window.open(
                `${process.env.REACT_APP_BASE_URL}/auth/api/v1/external/services/${provider}`,
                undefined,
                'toolbar=no,menubar=no,directories=no,status=no,width=800,height=600',
            );
    }
    
    return [openOAuthWindow];
}